import * as React from "react";
const AppleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#1E1E1E"
      d="M12.998 17.278c-.97.95-2.027.8-3.046.35-1.078-.46-2.067-.48-3.205 0-1.424.62-2.175.44-3.026-.35-4.826-5.03-4.114-12.689 1.365-12.969 1.335.07 2.265.74 3.046.8 1.167-.24 2.285-.93 3.53-.84 1.494.12 2.621.72 3.363 1.8-3.085 1.87-2.354 5.98.475 7.13-.564 1.5-1.296 2.989-2.512 4.089l.01-.01ZM8.033 4.249C7.885 2.02 9.675.18 11.732 0c.287 2.58-2.314 4.5-3.699 4.25Z"
    />
  </svg>
);
export default AppleIcon;
