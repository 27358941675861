import * as React from "react";
const WindowsMute = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.5 1.344v10.312a.469.469 0 0 1-.784.347L3.412 9H1.72A1.094 1.094 0 0 1 .625 7.906V5.094C.625 4.49 1.115 4 1.719 4h1.694L6.716.997a.469.469 0 0 1 .784.347ZM3.91 4.816a.468.468 0 0 1-.316.122H1.719a.156.156 0 0 0-.157.156v2.812c0 .087.07.157.157.157h1.875c.117 0 .23.043.315.121l2.654 2.413V2.403L3.909 4.816Zm6.265-.678a.469.469 0 1 0-.663.662l1.7 1.7-1.7 1.7a.469.469 0 1 0 .663.663l1.7-1.7 1.7 1.7a.469.469 0 1 0 .663-.663l-1.7-1.7 1.7-1.7a.468.468 0 0 0-.663-.662l-1.7 1.7-1.7-1.7Z"
    />
  </svg>
);
export default WindowsMute;
