import * as React from "react";
const Windows10Notification = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M0 0h16v13H0z" />
    <path stroke="#000" d="M3 3.5h10M3 6.5h10M3 9.5h6" />
    <path fill="#fff" d="M8.03 18.25 5 13h6.062l-3.031 5.25Z" />
    <circle
      cx={15.5}
      cy={15.5}
      r={8}
      fill="#191919"
      fillOpacity={0.63}
      stroke="url(#a)"
    />
    <path
      fill="#fff"
      d="M15.509 19.1c-.488 0-.923-.085-1.307-.253a2.218 2.218 0 0 1-.905-.7c-.22-.3-.339-.648-.356-1.043h1.115a.961.961 0 0 0 .217.56c.133.157.306.278.518.363.214.085.45.128.71.128.287 0 .54-.05.76-.15.223-.099.397-.237.523-.415a1.06 1.06 0 0 0 .188-.621c0-.244-.063-.458-.188-.643a1.226 1.226 0 0 0-.544-.44 2.085 2.085 0 0 0-.859-.16h-.614v-.895h.614c.27 0 .507-.049.71-.146.206-.097.367-.232.483-.405.116-.175.174-.38.174-.614 0-.225-.05-.42-.152-.586-.1-.168-.242-.3-.427-.394a1.384 1.384 0 0 0-.646-.142c-.237 0-.458.044-.664.131-.204.086-.37.209-.497.37a.95.95 0 0 0-.206.572h-1.062c.012-.394.128-.74.348-1.038a2.25 2.25 0 0 1 .88-.699c.365-.168.77-.252 1.215-.252.467 0 .87.091 1.208.273.34.18.603.42.788.721.187.3.28.63.277.987.002.408-.111.753-.34 1.037-.228.284-.531.475-.91.572v.057c.483.073.857.265 1.122.575.268.31.4.695.398 1.154.002.4-.109.759-.334 1.076a2.272 2.272 0 0 1-.912.75 3.1 3.1 0 0 1-1.325.27Z"
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(90 0 15.5) scale(8.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.865} stopColor="#595959" />
        <stop offset={1} stopColor="#ACA9A9" />
      </radialGradient>
    </defs>
  </svg>
);
export default Windows10Notification;
